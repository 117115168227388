import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Command, CommandActionEnum, ExecutionStateEnum, IngestionApi, Resource as IngestionResource, Job } from "../asset-service-api";
import { msalInstance } from "../msal-instance";
import { apiLoginRequest } from "../authConfig";
import { Configuration, Project, Resource, ResourceType } from "../api";
import { ITrack } from "../Projects/MapData";
import { getUserEmail } from "../graph";
import { useStore } from "../State/zustandStore";
import { transformTrackGroups } from "../Behaviors/projects";

async function getIngestApiConfig() {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No user signed in!");
    }

    let response;

    try {
        response = await msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account,
        });
    } catch {
        response = await msalInstance.acquireTokenPopup({
            ...apiLoginRequest,
            account: account,
        });
    }

    const bearer = `${response.accessToken}`;
    const configuration = new Configuration();

    configuration.basePath = process.env.REACT_APP_INGEST_API_BASE_PATH!;
    configuration.accessToken = bearer;
    return configuration;
}

async function GetIngestApi() {
    const config = await getIngestApiConfig();
    return new IngestionApi(config);
}

function formatError(error: AxiosError) {
    const messages = [] as string[];

    //@ts-expect-error
    for (const message of error.response?.data?.messages ?? []) {
        var keys = Object.getOwnPropertyNames(message);

        keys.filter(k => k !== "source").forEach(element => {
            messages.push( `   ${element}: ${message[element]}`);
        });
    }
    return messages.join("\n");
}

const useMutateJobs = () => {
    const queryClient = useQueryClient();
    const {logProjectEvent} = useStore().projects;
    return useMutation({
        mutationFn: async ({ command, projectId } : { command: Command, projectId: string, source: string }) => {
            const api = await GetIngestApi();
            return await api.ingestionJobsPost(undefined, undefined, command);
        },
        onSuccess: (response, variables) => {
            const job = response.data as Job;

            logProjectEvent(variables.projectId, `${variables.command.action} job (${variables.source}) created: ${response.data.id}`);
            queryClient.setQueryData(["job", job.id!], response.data);
        },
        onError: (error, variables) => {
            // This will log validation errors to the console
            //@ts-expect-error
            if (error.response?.data?.errors) {
                //@ts-expect-error
                var keys = Object.getOwnPropertyNames(error.response.data.errors);

                keys.forEach(element => {
                    //@ts-expect-error
                    console.error(`${element}: ${error.response.data.errors[element][0]}`);
                });
            }

            //@ts-expect-error
            const message = error?.response?.status === 409 ? `There is already an ingestion in progress for ${variables.command.upc}` : `Failed: ${error?.message ?? " no error message"}`;

            //@ts-expect-error
            logProjectEvent(variables.projectId, `${variables.command.action} job error:\n${formatError(error)}`);
            alert(message);
            return false;
        },
    });
};

function isJobRunning(jobState: ExecutionStateEnum, commandAction: CommandActionEnum) {
    switch (jobState) {
    case ExecutionStateEnum.Unknown:
    case ExecutionStateEnum.Created:
    case ExecutionStateEnum.Validating:
    case ExecutionStateEnum.CreatingBundle:
    case ExecutionStateEnum.Bundled:
    case ExecutionStateEnum.Processing:
        return true;
    case ExecutionStateEnum.Completed:
    case ExecutionStateEnum.Failed:
    case ExecutionStateEnum.Exception:
    case ExecutionStateEnum.Suspended:
    case ExecutionStateEnum.ValidationFailed:
        return false;
    case ExecutionStateEnum.Validated:
        return commandAction !== CommandActionEnum.Validate;
    default:
        return true;
    }
}

export function getJobQueryOptions(jobId: string, existingState?: ExecutionStateEnum, commandAction?: CommandActionEnum) {
    const running = !existingState || isJobRunning(existingState, commandAction!); // Job is not final unless it has data AND is in a final state
    return queryOptions({
        queryKey: ["job", jobId],
        queryFn: async () => {
            const jobsApi = await GetIngestApi();
            const response = await jobsApi.ingestionJobsJobIdGet(jobId);
            return response.data;
        },
        enabled: running, // disable once the job is in a final state
        refetchInterval: running ? 1000 : false, // keep refetching until job is in final state,
        notifyOnChangeProps: "all",
    });
}

function getMappedResource(project: Project, track: ITrack): Resource | undefined {
    const mapping = project?.trackResourceMap?.find(x => x.trackId === track.number.toString());
    return project?.resources?.find(x => x.id === mapping?.resourceId);
}

function getIngestCommandResources(project: Project) {
    const resources = [] as Resource[];

    if (project.resources?.every(r => r.resourceType === ResourceType.Audio)) {
        const tracks = project.releaseInfo!.trackGroups?.length > 1
            ? transformTrackGroups(project.releaseInfo!.trackGroups) /* AT-3499 Handle Vinyl/Cassette */
            : project.releaseInfo!.tracks;

        tracks.forEach((track) => {
            const mappedResource = getMappedResource(project, track);

            if (mappedResource) {
                resources.push({
                    uri: `${mappedResource.s3Path ?? ""}/${mappedResource.filename}`,
                    trackNumber: track.number,
                    trackGroup: (project.releaseInfo!.trackGroups?.length > 1) ? track.groupNumber : 0, /* AT-3499 Handle Vinyl/Cassette */
                    md5: mappedResource.md5,
                } as IngestionResource);
            }},
        );
    } else {
        project.resources?.forEach(r => { /*.filter(r => r.s3Path !== null)*/
            resources.push({
                uri: `${r.s3Path ?? "" }/${ r.filename}`, // TODO: can this include localpath?
                md5: r.md5,
            } as IngestionResource);
        });
    }
    return resources;
}

async function getValidationCommand(project: Project) {
    const resources = getIngestCommandResources(project);
    return {
        upc: project.releaseInfo?.upc,
        notes: project.notes,
        action: CommandActionEnum.Validate,
        resources: resources,
        overrideRestrictions: project.overrideRestrictions,
        correlationId: project.projectId,
    } as Command;
}

async function getIngestCommand(project: Project) {
    const resources = getIngestCommandResources(project);
    return {
        upc: project.releaseInfo?.upc,
        notes: project.notes,
        action: CommandActionEnum.Ingest,
        resources: resources,
        overrideRestrictions: project.overrideRestrictions,
        notifications: {
            email: [await getUserEmail()],
        },
        correlationId: project.projectId,
    } as Command;
}

const useIngestionJobs = () => {
    const queryClient = useQueryClient();

    function getJob(id: string) {
        return queryClient.getQueryData(["job", id]) as Job;
    }
    return {
        getJob,
    };
};

const useJob = (jobId: string | null | undefined) => {
    const queryClient = useQueryClient();
    const existing = queryClient.getQueryData<Job>(["job", jobId]);
    const options = getJobQueryOptions(jobId!, existing?.state, existing?.command?.action);

    // hooks can't be called conditionally.  so disable this query if no jobid passed in
    options.enabled = options.enabled && (jobId !== null && jobId !== undefined);

    const projectQuery = useQuery(options);
    return projectQuery.data;
};

export {
    useMutateJobs,
    getValidationCommand,
    getIngestCommand,
    isJobRunning,
    useIngestionJobs,
    useJob,
};
