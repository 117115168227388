import { FormatDuration, FormatTitle } from "../Formatting";
import { ITrack } from "./MapData";

interface Props {
    tracks: ITrack[]
}
export function TrackGroupBody(prop: Props) {
    return <>
        {
            prop.tracks.map(track => {
                return (
                    <tr key={`${track.isrc}unique`}>
                        <td colSpan={1} />
                        <td className={"word-break full-width"} colSpan={4}>
                            <span className={"spacer"}>{track.number}</span>
                            <span>{FormatTitle(track.title, track.versionTitle)}</span>
                        </td>
                        <td className={"numeric"} >{FormatDuration(track.duration)}</td>
                        <td className={"numeric"} colSpan={3}>{track.isrc}</td>
                    </tr>
                );
            })
        }
    </>;
}
