/* tslint:disable */
/* eslint-disable */
/**
 * Ingestion Tool UI
 * Ingestion Tool UI None
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * 
     * @type {boolean}
     * @memberof App
     */
    'enableHdForSd'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'version'?: string | null;
}
/**
 * 
 * @export
 * @interface Aspera
 */
export interface Aspera {
    /**
     * 
     * @type {Node}
     * @memberof Aspera
     */
    'node'?: Node;
}
/**
 * 
 * @export
 * @interface AudioInfo
 */
export interface AudioInfo {
    /**
     * 
     * @type {number}
     * @memberof AudioInfo
     */
    'bit_depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioInfo
     */
    'channels'?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioInfo
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioInfo
     */
    'sample_rate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AudioInfo
     */
    'isDolby'?: boolean;
}
/**
 * 
 * @export
 * @interface CprsManufacturer
 */
export interface CprsManufacturer {
    /**
     * 
     * @type {number}
     * @memberof CprsManufacturer
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CprsManufacturer
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CprsRelease
 */
export interface CprsRelease {
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'upc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'artist'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CprsRelease
     */
    'packageUpc'?: string | null;
    /**
     * 
     * @type {Array<CprsManufacturer>}
     * @memberof CprsRelease
     */
    'manufacturers'?: Array<CprsManufacturer> | null;
}
/**
 * 
 * @export
 * @interface DdpPqDescriptorPacket
 */
export interface DdpPqDescriptorPacket {
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'subcodePacketValid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'aTimeMinutes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'aTimeSeconds'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'aTimeFrame'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'controlByte1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'isrc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'upc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DdpPqDescriptorPacket
     */
    'trackNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DdpPqDescriptorPacket
     */
    'indexNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'duration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DdpPqDescriptorPacket
     */
    'durationMs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DdpPqDescriptorPacket
     */
    'sizeBytes'?: number;
}
/**
 * 
 * @export
 * @interface DdpTrackTitle
 */
export interface DdpTrackTitle {
    /**
     * 
     * @type {number}
     * @memberof DdpTrackTitle
     */
    'trackNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DdpTrackTitle
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'access_key'?: string | null;
}
/**
 * 
 * @export
 * @interface Path
 */
export interface Path {
    /**
     * 
     * @type {string}
     * @memberof Path
     */
    'source'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'startIngestion'?: boolean;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof Project
     */
    'resources'?: Array<Resource> | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'jobId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'jobStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'upc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isIngestRequestedAfterUpload'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'creatorId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'creatorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'lastModified'?: string | null;
    /**
     * 
     * @type {Array<TrackResource>}
     * @memberof Project
     */
    'trackResourceMap'?: Array<TrackResource> | null;
    /**
     * 
     * @type {ReleaseInfo}
     * @memberof Project
     */
    'releaseInfo'?: ReleaseInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'overrideRestrictions'?: Array<string> | null;
    /**
     * 
     * @type {Array<CprsRelease>}
     * @memberof Project
     */
    'cprsInformation'?: Array<CprsRelease> | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'versionNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface ReleaseInfo
 */
export interface ReleaseInfo {
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'upc': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'artists': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'embargoLiftDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'configuration': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'parentalAdvisory': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'resolutionType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'labelOwner': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'countryOwner': string;
    /**
     * 
     * @type {Array<Track>}
     * @memberof ReleaseInfo
     */
    'tracks': Array<Track>;
    /**
     * 
     * @type {Array<TrackGroup>}
     * @memberof ReleaseInfo
     */
    'trackGroups': Array<TrackGroup>;
    /**
     * 
     * @type {string}
     * @memberof ReleaseInfo
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseInfo
     */
    'embargoed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseInfo
     */
    'physicalScope'?: boolean;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'md5'?: string | null;
    /**
     * 
     * @type {ResourceType}
     * @memberof Resource
     */
    'resourceType'?: ResourceType;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'filename'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    's3Path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'localPath'?: string | null;
    /**
     * 
     * @type {UploadStatus}
     * @memberof Resource
     */
    'uploadStatus'?: UploadStatus;
    /**
     * 
     * @type {AudioInfo}
     * @memberof Resource
     */
    'audioInfo'?: AudioInfo;
    /**
     * 
     * @type {UploadError}
     * @memberof Resource
     */
    'uploadError'?: UploadError;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'uploadPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'asperaTransferId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'fileSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {Array<DdpPqDescriptorPacket>}
     * @memberof Resource
     */
    'pqDescriptorPackets'?: Array<DdpPqDescriptorPacket> | null;
    /**
     * 
     * @type {Array<DdpTrackTitle>}
     * @memberof Resource
     */
    'trackTitles'?: Array<DdpTrackTitle> | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'cdTextString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'pqPacketString'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceType = {
    Unknown: 'Unknown',
    Audio: 'Audio',
    File: 'File',
    DdpId: 'DdpId',
    DdpMs: 'DdpMs',
    DdpPq: 'DdpPq',
    DdpText: 'DdpText',
    CmfBd25: 'CmfBd25',
    CmfBd50: 'CmfBd50'
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];


/**
 * 
 * @export
 * @interface Tags
 */
export interface Tags {
    /**
     * 
     * @type {Aspera}
     * @memberof Tags
     */
    'aspera'?: Aspera;
}
/**
 * 
 * @export
 * @interface Track
 */
export interface Track {
    /**
     * 
     * @type {number}
     * @memberof Track
     */
    'groupNumber': number;
    /**
     * 
     * @type {number}
     * @memberof Track
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'isrc': string;
    /**
     * 
     * @type {number}
     * @memberof Track
     */
    'duration': number;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'versionTitle'?: string | null;
    /**
     * 
     * @type {Array<Track>}
     * @memberof Track
     */
    'children'?: Array<Track> | null;
}
/**
 * 
 * @export
 * @interface TrackGroup
 */
export interface TrackGroup {
    /**
     * 
     * @type {number}
     * @memberof TrackGroup
     */
    'groupNumber': number;
    /**
     * 
     * @type {number}
     * @memberof TrackGroup
     */
    'duration': number;
    /**
     * 
     * @type {Array<Track>}
     * @memberof TrackGroup
     */
    'tracks': Array<Track>;
}

/**
 * 
 * @export
 * @interface TrackResource
 */
export interface TrackResource {
    /**
     * 
     * @type {string}
     * @memberof TrackResource
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackResource
     */
    'trackId'?: string | null;
}
/**
 * 
 * @export
 * @interface TransferSpecResponse
 */
export interface TransferSpecResponse {
    /**
     * 
     * @type {Array<Path>}
     * @memberof TransferSpecResponse
     */
    'paths'?: Array<Path> | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'source_root'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'destination_root'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'direction'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'target_rate_cap_kbps'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'cipher'?: string | null;
    /**
     * 
     * @type {Tags}
     * @memberof TransferSpecResponse
     */
    'tags'?: Tags;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'rate_policy_allowed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'rate_policy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'target_rate_kbps'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'min_rate_cap_kbps'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'min_rate_kbps'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferSpecResponse
     */
    'lock_min_rate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransferSpecResponse
     */
    'create_dir'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'remote_host'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'remote_user'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof TransferSpecResponse
     */
    'sshfp'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'ssh_port'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferSpecResponse
     */
    'fasp_port'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferSpecResponse
     */
    'http_fallback'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    'authentication'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransferSpecResponse
     */
    'auth'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferSpecResponse
     */
    's3Path'?: string | null;
}
/**
 * 
 * @export
 * @interface UploadError
 */
export interface UploadError {
    /**
     * 
     * @type {number}
     * @memberof UploadError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadError
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UploadStatus = {
    Unknown: 'Unknown',
    Requested: 'Requested',
    InProgress: 'InProgress',
    Complete: 'Complete',
    Failed: 'Failed'
} as const;

export type UploadStatus = typeof UploadStatus[keyof typeof UploadStatus];


/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {Array<Project>}
     * @memberof Workspace
     */
    'projects'?: Array<Project> | null;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/App`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGet(options?: any): AxiosPromise<App> {
            return localVarFp.appGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public appGet(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).appGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsperaUploadApi - axios parameter creator
 * @export
 */
export const AsperaUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asperaUploadSpecGet: async (path?: string, projectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Aspera/UploadSpec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsperaUploadApi - functional programming interface
 * @export
 */
export const AsperaUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AsperaUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asperaUploadSpecGet(path?: string, projectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferSpecResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asperaUploadSpecGet(path, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AsperaUploadApi - factory interface
 * @export
 */
export const AsperaUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AsperaUploadApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asperaUploadSpecGet(path?: string, projectId?: string, options?: any): AxiosPromise<TransferSpecResponse> {
            return localVarFp.asperaUploadSpecGet(path, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsperaUploadApi - object-oriented interface
 * @export
 * @class AsperaUploadApi
 * @extends {BaseAPI}
 */
export class AsperaUploadApi extends BaseAPI {
    /**
     * 
     * @param {string} [path] 
     * @param {string} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsperaUploadApi
     */
    public asperaUploadSpecGet(path?: string, projectId?: string, options?: AxiosRequestConfig) {
        return AsperaUploadApiFp(this.configuration).asperaUploadSpecGet(path, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('_delete', 'projectId', projectId)
            const localVarPath = `/Projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<Resource>} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResources: async (projectId: string, resource?: Array<Resource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addResources', 'projectId', projectId)
            const localVarPath = `/Projects/{projectId}/resources`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMappedResources: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('clearMappedResources', 'projectId', projectId)
            const localVarPath = `/Projects/{projectId}/track-Map`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource: async (projectId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteResource', 'projectId', projectId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteResource', 'resourceId', resourceId)
            const localVarPath = `/Projects/{projectId}/resources/{resourceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('duplicateProject', 'projectId', projectId)
            const localVarPath = `/Projects/{projectId}/duplicate`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProject', 'id', id)
            const localVarPath = `/Projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapResource: async (projectId: string, trackId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('mapResource', 'projectId', projectId)
            // verify required parameter 'trackId' is not null or undefined
            assertParamExists('mapResource', 'trackId', trackId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('mapResource', 'resourceId', resourceId)
            const localVarPath = `/Projects/{projectId}/track-map/tracks/{trackId}/resources/{resourceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"trackId"}}`, encodeURIComponent(String(trackId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} isRequested 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIsIngestRequestedAfterUpload: async (projectId: string, isRequested: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setIsIngestRequestedAfterUpload', 'projectId', projectId)
            // verify required parameter 'isRequested' is not null or undefined
            assertParamExists('setIsIngestRequestedAfterUpload', 'isRequested', isRequested)
            const localVarPath = `/Projects/{projectId}/IsIngestRequestedAfterUpload/{isRequested}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"isRequested"}}`, encodeURIComponent(String(isRequested)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} jobId 
         * @param {string} [jobStartTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJobId: async (projectId: string, jobId: string, jobStartTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setJobId', 'projectId', projectId)
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('setJobId', 'jobId', jobId)
            const localVarPath = `/Projects/{projectId}/job/{jobId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (jobStartTime !== undefined) {
                localVarQueryParameter['jobStartTime'] = (jobStartTime as any instanceof Date) ?
                    (jobStartTime as any).toISOString() :
                    jobStartTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectNotes: async (projectId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setProjectNotes', 'projectId', projectId)
            const localVarPath = `/Projects/{projectId}/notes`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} overrideRestrictions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectOverrideRestrictions: async (projectId: string, overrideRestrictions: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setProjectOverrideRestrictions', 'projectId', projectId)
            // verify required parameter 'overrideRestrictions' is not null or undefined
            assertParamExists('setProjectOverrideRestrictions', 'overrideRestrictions', overrideRestrictions)
            const localVarPath = `/Projects/{projectId}/overrideRestrictions/{overrideRestrictions}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"overrideRestrictions"}}`, encodeURIComponent(String(overrideRestrictions)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectUpc: async (projectId: string, upc: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setProjectUpc', 'projectId', projectId)
            // verify required parameter 'upc' is not null or undefined
            assertParamExists('setProjectUpc', 'upc', upc)
            const localVarPath = `/Projects/{projectId}/product/{upc}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"upc"}}`, encodeURIComponent(String(upc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmapResource: async (projectId: string, trackId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('unmapResource', 'projectId', projectId)
            // verify required parameter 'trackId' is not null or undefined
            assertParamExists('unmapResource', 'trackId', trackId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('unmapResource', 'resourceId', resourceId)
            const localVarPath = `/Projects/{projectId}/track-map/tracks/{trackId}/resources/{resourceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"trackId"}}`, encodeURIComponent(String(trackId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {Resource} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource: async (projectId: string, resourceId: string, resource?: Resource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateResource', 'projectId', projectId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('updateResource', 'resourceId', resourceId)
            const localVarPath = `/Projects/{projectId}/resources/{resourceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {UploadStatus} [uploadStatus] 
         * @param {number} [uploadPercentage] 
         * @param {string} [s3Path] 
         * @param {string} [errorDescription] 
         * @param {number} [errorCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUploadState: async (projectId: string, resourceId: string, uploadStatus?: UploadStatus, uploadPercentage?: number, s3Path?: string, errorDescription?: string, errorCode?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateUploadState', 'projectId', projectId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('updateUploadState', 'resourceId', resourceId)
            const localVarPath = `/Projects/{projectId}/resources/{resourceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (uploadStatus !== undefined) {
                localVarQueryParameter['uploadStatus'] = uploadStatus;
            }

            if (uploadPercentage !== undefined) {
                localVarQueryParameter['uploadPercentage'] = uploadPercentage;
            }

            if (s3Path !== undefined) {
                localVarQueryParameter['s3Path'] = s3Path;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['errorDescription'] = errorDescription;
            }

            if (errorCode !== undefined) {
                localVarQueryParameter['errorCode'] = errorCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<Resource>} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResources(projectId: string, resource?: Array<Resource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResources(projectId, resource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearMappedResources(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearMappedResources(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResource(projectId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResource(projectId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapResource(projectId: string, trackId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapResource(projectId, trackId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} isRequested 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setIsIngestRequestedAfterUpload(projectId: string, isRequested: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setIsIngestRequestedAfterUpload(projectId, isRequested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} jobId 
         * @param {string} [jobStartTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setJobId(projectId: string, jobId: string, jobStartTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setJobId(projectId, jobId, jobStartTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectNotes(projectId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectNotes(projectId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} overrideRestrictions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectOverrideRestrictions(projectId: string, overrideRestrictions: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectOverrideRestrictions(projectId, overrideRestrictions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectUpc(projectId: string, upc: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectUpc(projectId, upc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmapResource(projectId: string, trackId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unmapResource(projectId, trackId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {Resource} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResource(projectId: string, resourceId: string, resource?: Resource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResource(projectId, resourceId, resource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {UploadStatus} [uploadStatus] 
         * @param {number} [uploadPercentage] 
         * @param {string} [s3Path] 
         * @param {string} [errorDescription] 
         * @param {number} [errorCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUploadState(projectId: string, resourceId: string, uploadStatus?: UploadStatus, uploadPercentage?: number, s3Path?: string, errorDescription?: string, errorCode?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUploadState(projectId, resourceId, uploadStatus, uploadPercentage, s3Path, errorDescription, errorCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<Resource>} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResources(projectId: string, resource?: Array<Resource>, options?: any): AxiosPromise<Project> {
            return localVarFp.addResources(projectId, resource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMappedResources(projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.clearMappedResources(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(options?: any): AxiosPromise<Project> {
            return localVarFp.createProject(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource(projectId: string, resourceId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.deleteResource(projectId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateProject(projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.duplicateProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapResource(projectId: string, trackId: string, resourceId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.mapResource(projectId, trackId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} isRequested 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIsIngestRequestedAfterUpload(projectId: string, isRequested: boolean, options?: any): AxiosPromise<Project> {
            return localVarFp.setIsIngestRequestedAfterUpload(projectId, isRequested, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} jobId 
         * @param {string} [jobStartTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJobId(projectId: string, jobId: string, jobStartTime?: string, options?: any): AxiosPromise<Project> {
            return localVarFp.setJobId(projectId, jobId, jobStartTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectNotes(projectId: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setProjectNotes(projectId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {boolean} overrideRestrictions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectOverrideRestrictions(projectId: string, overrideRestrictions: boolean, options?: any): AxiosPromise<Project> {
            return localVarFp.setProjectOverrideRestrictions(projectId, overrideRestrictions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectUpc(projectId: string, upc: string, options?: any): AxiosPromise<Project> {
            return localVarFp.setProjectUpc(projectId, upc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} trackId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmapResource(projectId: string, trackId: string, resourceId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.unmapResource(projectId, trackId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(project?: Project, options?: any): AxiosPromise<Project> {
            return localVarFp.updateProject(project, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {Resource} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource(projectId: string, resourceId: string, resource?: Resource, options?: any): AxiosPromise<Project> {
            return localVarFp.updateResource(projectId, resourceId, resource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} resourceId 
         * @param {UploadStatus} [uploadStatus] 
         * @param {number} [uploadPercentage] 
         * @param {string} [s3Path] 
         * @param {string} [errorDescription] 
         * @param {number} [errorCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUploadState(projectId: string, resourceId: string, uploadStatus?: UploadStatus, uploadPercentage?: number, s3Path?: string, errorDescription?: string, errorCode?: number, options?: any): AxiosPromise<Project> {
            return localVarFp.updateUploadState(projectId, resourceId, uploadStatus, uploadPercentage, s3Path, errorDescription, errorCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public _delete(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)._delete(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Array<Resource>} [resource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addResources(projectId: string, resource?: Array<Resource>, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).addResources(projectId, resource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public clearMappedResources(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).clearMappedResources(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteResource(projectId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteResource(projectId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public duplicateProject(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).duplicateProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} trackId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public mapResource(projectId: string, trackId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).mapResource(projectId, trackId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {boolean} isRequested 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setIsIngestRequestedAfterUpload(projectId: string, isRequested: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).setIsIngestRequestedAfterUpload(projectId, isRequested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} jobId 
     * @param {string} [jobStartTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setJobId(projectId: string, jobId: string, jobStartTime?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).setJobId(projectId, jobId, jobStartTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setProjectNotes(projectId: string, body?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).setProjectNotes(projectId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {boolean} overrideRestrictions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setProjectOverrideRestrictions(projectId: string, overrideRestrictions: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).setProjectOverrideRestrictions(projectId, overrideRestrictions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} upc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setProjectUpc(projectId: string, upc: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).setProjectUpc(projectId, upc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} trackId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public unmapResource(projectId: string, trackId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).unmapResource(projectId, trackId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(project?: Project, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} resourceId 
     * @param {Resource} [resource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateResource(projectId: string, resourceId: string, resource?: Resource, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateResource(projectId, resourceId, resource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} resourceId 
     * @param {UploadStatus} [uploadStatus] 
     * @param {number} [uploadPercentage] 
     * @param {string} [s3Path] 
     * @param {string} [errorDescription] 
     * @param {number} [errorCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateUploadState(projectId: string, resourceId: string, uploadStatus?: UploadStatus, uploadPercentage?: number, s3Path?: string, errorDescription?: string, errorCode?: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateUploadState(projectId, resourceId, uploadStatus, uploadPercentage, s3Path, errorDescription, errorCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGet(options?: any): AxiosPromise<Workspace> {
            return localVarFp.workspaceGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public workspaceGet(options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).workspaceGet(options).then((request) => request(this.axios, this.basePath));
    }
}


