import { useStore } from "../State/zustandStore";
import {CommandActionEnum, IngestionMessage, ValidationMessageType} from "../asset-service-api";
import {ITrack} from "../Projects/MapData";
import {IAsperaResource} from "../Projects/Actions/AsperaStatusButtonIcon";
import {useProjects} from "../Behaviors/projects";
import { useIngestionJobs, isJobRunning } from "../Behaviors/ingest.api";
import { UploadStatus } from "../api";
import {useHdValidator} from "./hd-bitdepth-validator";
import {useDurationDeltaValidators} from "./duration-delta-warning";
import {useBitDepthValidators} from "./bitDepthValidator";
import {useDolbyValidators} from "./doblyAudioValidator";
import {useSdValidator} from "./sd-bitdepth-validator";

export interface MessagePredicate {
    (message: IngestionMessage): boolean
}

export function errorFilter(messages: IngestionMessage[], predicate?: MessagePredicate) {
    return messages.filter(message =>
        ((message.messageType === "Exception") || (message.messageType === "Error"))
        && (message.source?.id !== "D5E689A0-6B90-407E-A101-23BCCE2A3F7F") // (must have at least one file. We deal with this in the UI
        && (message.source?.id !== "92A33EA4-FD71-4E33-B137-3E10AFF8B574") // (resource is not uploaded... rewritten as a pending upload in get pending uploads.
        && (!predicate || predicate(message))); // if there is no predicate, or the predicate passes.
}

export const useValidation = () => {

    const {getBitDepthInconsistentErrors, getSampleRateInconsistentErrors} = useBitDepthValidators();
    const {getDolbyAtmosErrors} = useDolbyValidators();
    const {getSdBitDepthErrors} = useSdValidator();
    const {getHdBitDepthErrors} = useHdValidator();
    const {durationDeltaWarning} = useDurationDeltaValidators();
    //const ingestionState = useIngestionJobState();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources, getMappedResource} = useProjects(projectId!);
    const {getJob} = useIngestionJobs();

    function getLocalValidationMessages(jobId: string | null | undefined) {
        return getHdBitDepthErrors()
            .concat(getSdBitDepthErrors())
            .concat(durationDeltaWarning())
            .concat(getSampleRateInconsistentErrors())
            .concat(getBitDepthInconsistentErrors())
            .concat(getDolbyAtmosErrors());

    }

    function getValidationMessages(jobId: string | null | undefined) {
        const messages = jobId ? getJob(jobId)?.messages ?? [] : [];
        const result = messages
            .map(x => x.source?.id === "D5E689A0-6B90-407E-A101-23BCCE2A3F7F" ? { //"No resources found."
                ...x, message: "At least one file must be mapped. ",
            } : x)
            .map(x => x.source?.id === "41462AA6-CC37-40CA-BFDB-289BA8D496C9" ? { // Track numbers must match upc.
                ...x,
                messageType: ValidationMessageType.Instruction,
                message: "Drop a file from the left to map it to this track.",
            } : x)
            .concat(getLocalValidationMessages(jobId));        return result;
    }

    function getValidationRestrictions(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        var result = messages.filter(message =>
            ((message.messageType === "Restricted" || message.messageType === "RestrictedResolvable" || message.messageType === "RestrictedResolved"))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
        return result;
    }

    function getValidationWarnings(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        return messages.filter(message =>
            ((message.messageType === "Warning"))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
    }

    function getValidationInstructions(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        var result = messages.filter(message =>
            ((message.messageType === "Instruction"))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
        return result;
    }

    function getValidationErrors(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        return errorFilter(messages, predicate);
    }

    function getTrackValidationWarnings(jobId: string | null | undefined, track: ITrack) {
        var result = getValidationWarnings(jobId,
            ((x) => (x.source?.scope === "Track" && x.source.target === track.number.toString())));
        return result;
    }

    function getTrackValidationRestrictions(jobId: string | null | undefined, track: ITrack) {
        const messages = getValidationMessages(jobId);
        const restrictions = messages.filter(message => (message.messageType === "Restricted")
            || (message.messageType === "RestrictedResolvable") || (message.messageType === "RestrictedResolved"));
        var result = restrictions.filter((x) => (x.source?.scope === "Track" && x.source.target === track.number.toString()));
        return result;
    }
    function hasTrackValidationRestrictions(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationRestrictions(jobId, track).length > 0;
    }

    function getTrackValidationInstructions(jobId: string | null | undefined, track: ITrack, hasFile: boolean) {
        var result = getValidationInstructions(jobId, (
            (x) => (x.source?.scope === "Track"
                && x.source.target === track.number.toString() // this track.
                && (!hasFile || x.source.id !== "41462AA6-CC37-40CA-BFDB-289BA8D496C9")) // drop a file
        ),
        );
        return result;
    }

    function getTrackValidationErrors(jobId: string | null | undefined, track: ITrack) {
        var result = getValidationErrors(jobId, (
            (x) => (x.source?.scope === "Track" && x.source.target === track.number.toString()) // this track.
        ),
        );
        return result;
    }

    function getProductValidationWarnings(jobId: string | null | undefined) {
        return getValidationWarnings(jobId, (x) => x.source?.scope === "Product");
    }

    function getProductValidationErrors(jobId: string | null | undefined) {
        return getValidationErrors(jobId, (x) => x.source?.scope === "Product");
    }

    function getProductValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.source?.scope === "Product");
    }

    function hasValidationErrors(jobId: string | null | undefined) {
        return getValidationErrors(jobId).length > 0;
    }

    function hasValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId).length > 0;
    }

    function hasValidationWarnings(jobId: string | null | undefined) {
        return getValidationWarnings(jobId).length > 0;
    }

    function hasProductValidationErrors(jobId: string | null | undefined) {
        return getProductValidationErrors(jobId).length > 0;
    }

    function hasProductValidationRestrictions(jobId: string | null | undefined) {
        return getProductValidationRestrictions(jobId).length > 0;
    }
    function hasUnresolvedValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.messageType !== "RestrictedResolved").length > 0;
    }

    function hasOverridableValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.messageType === "RestrictedResolvable" || x.messageType === "RestrictedResolved").length > 0;
    }

    function hasProductValidationWarnings(jobId: string | null | undefined) {
        return getProductValidationWarnings(jobId).length > 0;
    }

    function hasTrackValidationWarnings(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationWarnings(jobId, track).length > 0;
    }

    function hasTrackValidationInstructions(jobId: string | null | undefined, track: ITrack, hasFile: boolean) {
        return getTrackValidationInstructions(jobId, track, hasFile).length > 0;
    }
    function hasTrackValidationErrors(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationErrors(jobId, track).length > 0;
    }

    // TODO: fix
    function isIngestionJobShown() {
        //return ingestionState.isIngestInProgress || ingestionState.isIngestCompleteOrFailed;
    }

    const isValidationInProgress = (jobId: string | null | undefined) => {
        if (jobId) {
            const job = getJob(jobId);
            return job && (job.command?.action === CommandActionEnum.Validate && isJobRunning(job.state!, job.command.action));
        }
        return false;
    };

    function getPendingUploadMessages(jobId : string | null | undefined) {

        if(!project?.isIngestRequestedAfterUpload) return [];
        return getValidationMessages(jobId)
            .filter(x => (x.source!.id === "6B360297-7B37-498A-A2FA-B546F148ED4A") && (x.source?.scope === "Track") && x.messageType === "Error")
            .map(x => x.source?.id === "6B360297-7B37-498A-A2FA-B546F148ED4A" ? { //"No resources found."
                ...x, message: "Waiting for upload to complete.",
                messageType: "UploadInProgress",

            } : x);
    }

    function getPendingUploads() {
        const mappedResources = getMappedResources();
        return mappedResources.filter(x => x.uploadStatus !== UploadStatus.Complete);
    }

    function hasPendingUpload(): boolean {
        const pendingResources = getPendingUploads();
        return pendingResources.length > 0;
    }

    function hasTrackPendingUpload(track: ITrack) {
        const resource = getMappedResource(track);

        if (resource as IAsperaResource) {
            return (resource?.uploadStatus !== UploadStatus.Complete);
        }
        return false;
    }
    return {
        isIngestionJobShown,
        isValidationInProgress,
        hasValidationErrors,
        getValidationErrors,
        getValidationRestrictions,
        hasValidationRestrictions,
        hasUnresolvedValidationRestrictions,
        hasOverridableValidationRestrictions,
        getValidationWarnings,
        hasValidationWarnings,
        hasProductValidationErrors,
        getProductValidationErrors,
        hasProductValidationRestrictions,
        getProductValidationRestrictions,
        hasProductValidationWarnings,
        getProductValidationWarnings,
        hasTrackValidationWarnings,
        getTrackValidationWarnings,
        hasTrackValidationErrors,
        getTrackValidationErrors,
        getPendingUploadMessages,
        hasPendingUpload,
        hasTrackPendingUpload,
        getValidationMessages,
        getValidationInstructions,
        getTrackValidationInstructions,
        hasTrackValidationInstructions,
        hasTrackValidationRestrictions,
        getTrackValidationRestrictions,
    };
};
