import {IngestionMessage} from "../asset-service-api";
import {ITrack} from "../Projects/MapData";
import {FormatDuration} from "../Formatting";
import {Resource} from "../api";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useDurationDeltaValidators = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {getMappedTrack, getMappedResources} = useProjects(projectId!);

    const durationDeltaWarning = (): IngestionMessage[] => {
        const tolerance = 5;

        function isDurationEqual(item: ITrack, iAudioResource: Resource): boolean {
            return (Math.abs((item?.duration ?? 0) - (iAudioResource?.audioInfo?.duration ?? 0))) < tolerance;
        }

        const mappedResources = getMappedResources();
        const errorResources = mappedResources.filter(x => !isDurationEqual(getMappedTrack(x)!, x));
        return errorResources.map(x => {
            return {
                details: `The duration difference (${ FormatDuration((x.audioInfo?.duration ?? 0) - getMappedTrack(x)?.duration!) }) between _Asset ${
                    x.filename } (${ FormatDuration((x.audioInfo?.duration ?? 0)) }) and Track ${ getMappedTrack(x)?.number
                } (${ FormatDuration(getMappedTrack(x)?.duration!) }), exceeds the warning threshold (${ FormatDuration(tolerance) })`,
                message: `The difference between the Tracks expected and actual duration exceeds ${ tolerance } seconds.`,
                messageType: "Warning",
                source: {
                    scope: "Track",
                    stage: "local",
                    target: getMappedTrack(x)?.number.toString(),
                    id: "601950E4-080F-4FB7-84A8-7D66C0879A96",
                },
            } as IngestionMessage;
        });
    };
    return {durationDeltaWarning};
};
