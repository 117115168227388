import IcecreamIcon from "@mui/icons-material/Icecream";
import {ValidationMessageType} from "../asset-service-api";
import {useValidation} from "../validation/validation";
import { useStore } from "../State/zustandStore";
import { useProjects } from "../Behaviors/projects";
import {
    UploadInProgressIcon,
    ValidationBusyIcon,
    ValidationErrorIcon,
    ValidationExceptionIcon,
    ValidationInformationIcon,
    ValidationRestrictionIcon,
    ValidationRestrictionResolvableIcon,
    ValidationRestrictionResolvedIcon,
    ValidationSuccessIcon,
    ValidationUnknownIcon,
    ValidationWarningIcon,
    ValidationInstructionIcon,
} from "./Icons";

export interface Props {
    messageType: ValidationMessageType | string
}

export const ValidationMessageTypeIconGroup = () => {

    const {getValidationMessages,
        getValidationErrors,
        isValidationInProgress} = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const messages = getValidationMessages(project?.jobId);

    if (isValidationInProgress(project?.jobId)) return <><ValidationBusyIcon className={"me-2"} size={"large"} /> Validating.</>;

    const errors = getValidationErrors(project?.jobId);
    const warnings = messages.filter(x => x.messageType === "Warning");
    const restrictions = messages.filter(x => x.messageType === "Restricted");
    const restrictionsResolvable = messages.filter(x => x.messageType === "RestrictedResolvable");
    const restrictionsResolved = messages.filter(x => x.messageType === "RestrictedResolved");
    var countSummary = [];

    if (errors.length > 0)
        countSummary.push(<><ValidationErrorIcon /> {errors.length} error(s) </>);

    if (restrictions.length > 0)
        countSummary.push(<><ValidationRestrictionIcon /> {restrictions.length} restriction(s) </>);

    if (restrictionsResolvable.length > 0)
        countSummary.push(<><ValidationRestrictionResolvableIcon /> {restrictionsResolvable.length} restriction(s) </>);

    if (restrictionsResolved.length > 0)
        countSummary.push(<><ValidationRestrictionResolvedIcon /> {restrictionsResolved.length} restriction(s) </>);

    if (warnings.length > 0)
        countSummary.push(<><ValidationWarningIcon /> {warnings.length} warning(s) </>);

    if (countSummary.length > 0)
        return <>{ countSummary }</>;
    return <><ValidationSuccessIcon className={"me-2"} size={"large"} /> Ready to ingest.</>;
};

export const ValidationMessageTypeIcon = (props: Props) => {

    switch (props.messageType) {
    case "Error":
        return <ValidationErrorIcon />;
    case "Information":
        return <ValidationInformationIcon />;
    case "Instruction":
        return <ValidationInstructionIcon />;
    case "Warning":
        return <ValidationWarningIcon />;
    case "Restricted":
        return <ValidationRestrictionIcon />;
    case "RestrictedResolvable":
        return <ValidationRestrictionResolvableIcon />;
    case "RestrictedResolved":
        return <ValidationRestrictionResolvedIcon />;
    case "Exception":
        return <ValidationExceptionIcon />;
    case "UploadInProgress":
        return <UploadInProgressIcon />;
    case "Unknown":
        return <ValidationUnknownIcon />;
    default:
        return <IcecreamIcon />;
    }
};
